import React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {observable} from "mobx";
import history from "../../helper/browserHistory";
import {withTranslation} from "react-i18next";
import userStore from "../../stores/userStore"
import {createText, updateText} from "../../helper/util"
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../../helper/util";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import {Label} from "semantic-ui-react";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";

let Editor = null;
if (typeof window !== "undefined") {
    Editor = require("../../components/moap-components/TextEditor").default
}

@observer
class TextStoreHTMLEditor extends React.Component {
    @observable selectedImage = null;
    @observable selectedImagePreview = null;
    @observable language = 'de-DE';


    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
            editState: false,
            text: "",
            isEditSource: false
        }

    }

    componentDidMount() {
        if (typeof this.props.textStore[this.props.id] === "undefined") {
            fetchTextById(this.props.id).then(text => {
                if (typeof text !== "undefined") {
                    this.setState({
                        ...this.state,
                        textStore: {[this.props.id]: text[this.props.id]}
                    })
                }
            })
        }
    }

    componentWillUnmount() {
        if (!userStore.isAdmin) {
            history.push("/");
        }
        if (this.selectedImagePreview !== null && this.selectedImage !== null) {
            URL.revokeObjectURL(this.selectedImagePreview);
            this.selectedImage = null;
            this.selectedImagePreview = null;
        }
    }

    onEditorStateChange(editorState) {
        this.editorState = editorState;
    };

    uploadImageCallBack(file) {

        return new Promise(
            (resolve, reject) => {
                this.selectedImage = file;
                this.selectedImagePreview = URL.createObjectURL(file);
                resolve({data: {link: this.selectedImagePreview}});
            }
        );
    }

    handleChange = (e, {value}) => this.language = value;

    valueChange(e) {
        this.setState({...this.state, text: e.target.value})
    }

    render() {
        let {id} = this.props;
        const textStore = this.state.textStore;
        let textToShow = {content: ""};
        let contentToShow;
        if (typeof textStore[id] !== "undefined") {
            if (typeof textStore[id][this.language] !== "undefined") {
                textToShow = textStore[id][this.language];
            } else {
                textToShow = {content: ""};
            }
        }
        if (this.state.editState) {
            let style = {
                "zIndex": 1,
                "position": "relative",
                "backgroundColor": "white",
                "overflow": "auto"
            };

            contentToShow = <div style={style}>
                <Form>
                    <Form.Field style={{display: "inline-flex"}}>
                        <label style={{paddingRight: "5px"}}>Text als HTML bearbeiten:</label>
                        <Form.Radio toggle={true}
                                    checked={this.state.isEditSource}
                                    onChange={() => {
                                        this.setState(prevState => ({
                                            ...this.state,
                                            isEditSource: !prevState.isEditSource
                                        }));
                                    }}>
                        </Form.Radio>
                    </Form.Field>
                    <Form.Field>
                        {!this.state.isEditSource ? Editor &&
                            <Editor text={this.state.text}
                                    format={"html"}
                                    updateText={(text) => {
                                        this.setState({...this.state, text: text});
                                    }}/>
                            :
                            <Form.Field>
                                <TextArea className={"edit-source-textarea"}
                                          value={this.state.text}
                                          onChange={e => this.valueChange(e)}
                                />
                            </Form.Field>}
                    </Form.Field>
                    <Form.Field>
                        <Button.Group>
                            <Button color="green" onClick={() => {
                                let textToEdit = {
                                    content: this.state.text,
                                    title: '',
                                    description: ''
                                };

                                if (this.selectedImage !== null && this.selectedImagePreview !== null) {
                                    let formData = new FormData();
                                    formData.append("image", this.selectedImage);
                                    fetch(config.BASE_URL + "texts/image/" + id, {
                                        method: "POST",
                                        headers: {
                                            "Access-Control-Allow-Origin": "*",
                                            "Authorization": "Bearer " + getTokenFromLocalStorage()
                                        },
                                        body: formData
                                    }).then(response => {
                                        if (response.status >= 200 && response.status < 300) {
                                            response.json().then(res => {

                                                textToEdit.content = this.state.text.replace(this.selectedImagePreview, res.data.link);
                                                if (typeof textStore[id] === "undefined" || typeof textStore[id][this.language] === "undefined") {
                                                    createText(id, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                                        if (isSuccess) {
                                                            fetchTextById(id).then(text => {
                                                                textStore[id] = text[id];
                                                                this.setState({...this.state, editState: false})
                                                            })
                                                        }
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    });
                                                } else {
                                                    updateText(id, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                                        if (isSuccess) {
                                                            fetchTextById(id).then(text => {
                                                                textStore[id] = text[id];
                                                                this.setState({...this.state, editState: false})
                                                            })

                                                        }
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    });
                                                }
                                                if (this.selectedImagePreview !== null && this.selectedImage !== null) {
                                                    URL.revokeObjectURL(this.selectedImagePreview);
                                                    this.selectedImage = null;
                                                    this.selectedImagePreview = null;
                                                }
                                            });
                                        } else {
                                            if (this.selectedImagePreview !== null && this.selectedImage !== null) {
                                                URL.revokeObjectURL(this.selectedImagePreview);
                                                this.selectedImage = null;
                                                this.selectedImagePreview = null;
                                            }
                                            this.error = "Error on fetching";
                                        }
                                    }).catch(
                                        error => {
                                            if (this.selectedImagePreview !== null && this.selectedImage !== null) {
                                                URL.revokeObjectURL(this.selectedImagePreview);
                                                this.selectedImage = null;
                                                this.selectedImagePreview = null;
                                            }
                                            this.error = "Error on fetching";

                                        }
                                    );
                                } else {
                                    if (typeof textStore[id] === "undefined" || typeof textStore[id][this.language] === "undefined") {
                                        createText(id, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                            if (isSuccess) {
                                                fetchTextById(id).then(text => {
                                                    textStore[id] = text[id];
                                                    this.setState({...this.state, editState: false})
                                                })
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    } else {
                                        updateText(id, textToEdit, this.language, this.props.namespace).then(isSuccess => {
                                            if (isSuccess) {
                                                fetchTextById(id).then(text => {
                                                    textStore[id] = text[id];
                                                    this.setState({...this.state, editState: false})
                                                })
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    }
                                    if (this.selectedImagePreview !== null && this.selectedImage !== null) {
                                        URL.revokeObjectURL(this.selectedImagePreview);
                                        this.selectedImage = null;
                                        this.selectedImagePreview = null;
                                    }
                                }


                            }}>Speichern</Button>
                            <Button color="red"
                                    onClick={() => this.setState({...this.state, editState: false})}>Abbrechen</Button>
                        </Button.Group>
                    </Form.Field>
                </Form>
            </div>
        } else {
            contentToShow = <div>
                <div id={id} dangerouslySetInnerHTML={{__html: textToShow.content}}/>
                {textToShow.isNamespace === this.props.namespace ? "" :
                    <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                           color={"red"}>!</Label>}
                <br/>
                <Button color="orange" icon="edit outline" onClick={() => {
                    this.setState({...this.state, editState: true, text: textToShow.content})
                }}/>
            </div>
        }

        return (
            contentToShow
        );
    }
}

TextStoreHTMLEditor.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    id: PropTypes.string,
};

TextStoreHTMLEditor.defaultProps = {
    textStore: {},
    namespace: null,
    id: Math.random().toString()
};

export default withTranslation()(TextStoreHTMLEditor)
